import { configValue } from "@/services/config";
import { ref } from "vue";
import type { CompanyInformation } from "@/core/api/data-contracts";

function getApplicationId() {
  return configValue("VUE_APP_INTERCOM_APP_ID");
}

function intercomInstance(): Intercom_.IntercomStatic | null {
  return getApplicationId() ? globalThis.Intercom : null;
}

const messageCounter = ref(0);

export function useIntercomMessageCounter() {
  return { messageCounter };
}

export function intercomUpdate() {
  intercomInstance()?.("update");
}

type IntercomBoot = {
  user_id: string;
  email: string;
  mainRole: string;
  isBetaUser: boolean;
  activeCompany: CompanyInformation;
};

export function intercomBoot({ user_id, email, mainRole, isBetaUser, activeCompany }: IntercomBoot) {
  const applicationId = getApplicationId();
  const settings: Intercom_.IntercomSettings = {
    app_id: applicationId,
    email: email,
    user_id: user_id,
    userProfession: mainRole,
    userFeatureSet: isBetaUser ? "beta" : "normal",
    company: {
      id: activeCompany.id,
      name: activeCompany.name,
    },
    hide_default_launcher: true,
  };

  intercomInstance()?.("boot", settings);

  // We'll have to wait until Intercom is booted until we attach the listener
  // There's no promise or callback that we can rely on
  setTimeout(() => {
    intercomOnUnreadCountChange((count) => (messageCounter.value = count));
  }, 2000);
}

export function intercomTrackEvent(eventName: string, metaData?: object): void {
  intercomInstance()?.("trackEvent", eventName, metaData ?? {});
}

export function intercomShutdown() {
  intercomInstance()?.("shutdown");
}

export function intercomShow() {
  intercomInstance()?.("show");
}

export function intercomHide() {
  intercomInstance()?.("hide");
}

function intercomOnUnreadCountChange(onUnreadCountChange: (count: number) => void) {
  intercomInstance()?.("onUnreadCountChange", onUnreadCountChange);
}

export function intercomShowArticle(article: ArticleId) {
  const articleId = articles[article];
  intercomInstance()?.("showArticle", articleId);
}

const articles = {
  "time-report": 6976615,
  "comments-mentions": 6973498,
  "work-order-files": 6976549,
  "work-order-states": 8974724,
  "self-inspections": 9018905,
  "project-subtasks": 9296117,
  "zendesk-information": 9329910,
} as const;

export type ArticleId = keyof typeof articles;
