import { getApplicationVersion } from "@/services/api";
import { configValue } from "@/services/config";
import { intercomBoot, intercomShutdown } from "@/services/intercom";
import { applicationInsightsInstance } from "@/services/application-insights/application-insights";
import { trackUnknownError } from "@/services/application-insights/track-unknown-error";
import type { ToastArgs } from "@/components/Toaster/types";
import { defineStore } from "pinia";
import axios from "axios";
import type { CompanyInformation, UserMeResponse, UserProfile } from "@/core/api/data-contracts";

export type UserState =
  | {
      isAuthenticated: true;
      roles: string[];
      profile: UserProfile;
      companies: CompanyInformation[];
      activeCompany: CompanyInformation;
    }
  | {
      isAuthenticated: false;
      roles: string[];
      profile: null;
      companies: CompanyInformation[];
      activeCompany: null;
    };

export type UserRole =
  | "se.eaztimate.hubb"
  | "se.eaztimate.hubb.arbetsledare"
  | "se.eaztimate.hubb.utforare"
  | "se.eaztimate.hubb.beta"
  | "se.eaztimate.hubb.dev";

export type AuthenticationState = "missing" | "initialized" | "unknown";

export type StoreState = {
  authenticationState: AuthenticationState;
  user: UserState;
  application: {
    currentVersion?: string;
    newVersion: null | string;
    hasNewVersion: boolean;
  };
};

const roles = {
  foreman: "se.eaztimate.hubb.arbetsledare",
  technician: "se.eaztimate.hubb.utforare",
  beta: "se.eaztimate.hubb.beta",
  developer: "se.eaztimate.hubb.dev",
} as const;

type MainRoles = "foreman" | "worker" | "anonymous";

const anonymousUser: UserState = {
  isAuthenticated: false,
  profile: null,
  companies: [],
  roles: [],
  activeCompany: null,
} as const;

export const useAuthenticatedUserStore = defineStore("authenticatedUser", {
  state: (): StoreState => ({
    authenticationState: "missing",
    user: anonymousUser,
    application: {
      currentVersion: configValue("VUE_APP_APPLICATION_VERSION"),
      newVersion: null,
      hasNewVersion: false,
    },
  }),
  getters: {
    isForeman: (state: StoreState): boolean => isInRole(state, roles.foreman),
    isTechnician: (state: StoreState): boolean => isInRole(state, roles.technician),
    isBetaGroup: (state: StoreState): boolean => isInRole(state, roles.beta),
    isDeveloper: (state: StoreState): boolean => isInRole(state, roles.developer),
    hasAccessToWorkOrderCreate(): boolean {
      return this.isForeman;
    },
    hasAccessToWorkOrderRiskRead(): boolean {
      return this.isForeman || this.isTechnician;
    },
    canEditLabels(): boolean {
      return this.isForeman;
    },
    hasAccessToWorkOrderCostRead(): boolean {
      return this.isForeman;
    },
    hasAccessToWorkOrderInvoiceRead(): boolean {
      return this.isForeman;
    },
    hasAccessToSelfInspections(): boolean {
      return this.isForeman || this.isTechnician;
    },
    mainRole(): MainRoles {
      if (this.isForeman) {
        return "foreman";
      } else if (this.isTechnician) {
        return "worker";
      } else {
        return "anonymous";
      }
    },
    userName(): string | null {
      if (this.user.profile) {
        return this.user.profile.name;
      } else {
        return null;
      }
    },
  },
  actions: {
    applicationVersion({ version }: { version: string }) {
      this.application.hasNewVersion = true;
      this.application.newVersion = version;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    toastAdd(toast: ToastArgs) {
      // NOP: This event is being handled by useToaster()
    },
    async updateVersion({ source }: { source: "user-init" | "user-update" }) {
      if (this.application.hasNewVersion) {
        // No need to check if we have detected a new version already
        return;
      }

      try {
        const { data } = await getApplicationVersion();
        if (data.version !== this.application.currentVersion) {
          this.applicationVersion({ version: data.version });
          applicationInsightsInstance().trackEvent({
            name: "Application Version Mismatch",
            properties: {
              eventCategory: "Application",
              source: source,
              currentVersion: this.application.currentVersion,
              latestVersion: data.version,
            },
          });
        }
      } catch {
        // Version tracking should not affect the login
      }
    },
    async userLogin(path: string) {
      // We're leaving the site now, so it's better to flush AI before.
      applicationInsightsInstance().flush();
      location.href = `/api/user/login?redirect=${path}`;
    },
    async userLogout() {
      intercomShutdown();
      // We're leaving the site now, so it's better to flush AI before.
      applicationInsightsInstance().flush();
      location.href = "/api/user/logout";
    },
    async updateUser(userData: UserState, options: { source: "user-init" | "user-update" }) {
      this.user = userData;
      this.authenticationState = "initialized";

      if (userData.isAuthenticated && options.source === "user-init") {
        intercomBoot({
          user_id: userData.profile.userId.toString(),
          email: userData.profile.email,
          mainRole: this.mainRole,
          isBetaUser: this.isBetaGroup,
          activeCompany: userData.activeCompany,
        });
      }

      await this.updateVersion(options);
    },
    /** To be able to execute http requests we'll need to make sure that the user is initialized */
    async requireUser(): Promise<UserState> {
      await this.userInit();
      return this.user;
    },
    /** Forces reevaluation of the user since we can not be sure if the user is logged in anymore */
    revaluateAuthenticatedUser() {
      this.authenticationState = "unknown";
    },
    async userInit() {
      // NOTE: since there are multiple async calls this call might be racy and we might need to add something like
      // https://github.com/DirtyHairy/async-mutex
      const state = this.authenticationState;
      if (state === "initialized") {
        return;
      }

      try {
        const user = await getCurrentUser();
        const preferredCompanyId = getPreferredCompanyId();

        const updatedUserState = toUserState(user, preferredCompanyId);
        await this.updateUser(updatedUserState, { source: state === "missing" ? "user-init" : "user-update" });
      } catch (e) {
        trackUnknownError(applicationInsightsInstance(), e);
        await this.userLogout();
      }
    },
  },
});

function toUserState(user: UserMeResponse, preferredCompanyId: string | null): UserState {
  if (!user.isAuthenticated || !user.profile) {
    return anonymousUser;
  }

  const preferredCompany = preferredCompanyId
    ? user.companies.find((company) => company.id === preferredCompanyId)
    : null;

  return {
    isAuthenticated: true,
    profile: user.profile,
    roles: user.roles,
    companies: user.companies,
    activeCompany: preferredCompany ?? user.profile.mainCompany,
  };
}

function isInRole(state: StoreState, role: UserRole) {
  return state.user.roles.includes(role);
}

async function getCurrentUser() {
  const user = await axios.get<UserMeResponse>("/api/user/me");

  return user.data;
}

export function useCompanySelector() {
  const store = useAuthenticatedUserStore();

  const selectCompany = async (companyId: string | null) => {
    if (store.user.isAuthenticated) {
      store.user.activeCompany =
        store.user.companies.find((company) => company.id === companyId) ?? store.user.profile.mainCompany;
    } else {
      store.user.activeCompany = null;
    }

    if (
      companyId !== null &&
      companyId === store.user.activeCompany?.id &&
      store.user.activeCompany?.id !== store.user.profile?.mainCompany.id
    ) {
      sessionStorage.setItem(CompanyIdLocalStorageKey, companyId);
    } else {
      sessionStorage.removeItem(CompanyIdLocalStorageKey);
    }
    window.location.href = "/";
  };

  return { selectCompany };
}

const CompanyIdLocalStorageKey = "company-id";

function getPreferredCompanyId() {
  return sessionStorage.getItem(CompanyIdLocalStorageKey);
}
