import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import type { ITelemetryItem } from "@microsoft/applicationinsights-core-js";
import { configValue } from "@/services/config";
import { generateW3CId } from "@microsoft/applicationinsights-core-js";
import type { Router, RouteRecordName } from "vue-router";
import { useAuthenticatedUserStore } from "@/services/store";
import type { App } from "vue";

let _instance: ApplicationInsights;

export type ApplicationInsightsConfig = {
  instrumentationKey: string;
  applicationVersion: string;
  cloudRoleName: string;
};

export const VUE_APPLICATION_INSIGHTS = Symbol("VUE_APPLICATION_INSIGHTS");

function createApplicationInstanceVuePlugin(applicationInsights: ApplicationInsights) {
  return {
    install: (app: App) => {
      app.provide(VUE_APPLICATION_INSIGHTS, applicationInsights);
    },
  };
}

function resolveConfiguration(): ApplicationInsightsConfig {
  return {
    applicationVersion: configValue("VUE_APP_APPLICATION_VERSION"),
    cloudRoleName: configValue("VUE_APP_AZURE_APP_INSIGHT_APP"),
    instrumentationKey: configValue("VUE_APP_AZURE_APP_INSIGHT_ID"),
  };
}

export function createApplicationInsights(router: Router) {
  if (_instance) {
    throw new Error("ApplicationInsights already initialized");
  }

  const config = resolveConfiguration();

  _instance = new ApplicationInsights({
    config: {
      instrumentationKey: config.instrumentationKey,
      enableAjaxErrorStatusText: true,
      enableAutoRouteTracking: false,
      autoTrackPageVisitTime: true,
      enableCorsCorrelation: true,
      correlationHeaderExcludedDomains: ["googleapis.com"],
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
    },
  });

  _instance.loadAppInsights();
  _instance.addTelemetryInitializer(setupTelemetry(config));
  setupPageTracking(_instance, router, config.cloudRoleName);

  return {
    applicationInsights: _instance,
    applicationInsightsPlugin: createApplicationInstanceVuePlugin(_instance),
  };
}

function setupPageTracking(applicationInsights: ApplicationInsights, router: Router, cloudRoleName: string) {
  router.beforeEach((route, from, next) => {
    applicationInsights.context.telemetryTrace.traceID = generateW3CId();
    const routeName = safeRouteName(route.name);
    applicationInsights.context.telemetryTrace.name = routeName;
    applicationInsights.startTrackPage(`${cloudRoleName} / ${routeName}`);
    next();
  });

  router.afterEach((route) => {
    const name = `${cloudRoleName} / ${safeRouteName(route.name)}`;
    applicationInsights.stopTrackPage(name, location.href);
    applicationInsights.flush();
  });
}

function safeRouteName(route: RouteRecordName | null | undefined): string {
  if (route == null) {
    return "no-name";
  }

  if (typeof route === "symbol") {
    return route.description ?? "no-name";
  }

  return route;
}

function setupTelemetry(config: ApplicationInsightsConfig) {
  const store = useAuthenticatedUserStore();

  return function (envelope: ITelemetryItem) {
    if (envelope.tags) {
      envelope.tags["ai.cloud.role"] = config.cloudRoleName;
      envelope.tags["ai.application.ver"] = config.applicationVersion;
    }

    const authenticatedUserId = store.user.profile?.userId.toString();
    if (envelope.data) {
      envelope.data["companyId"] = store.user.activeCompany?.id;
      envelope.data["userProfession"] = store.mainRole;
      envelope.data["userFeatureSet"] = store.isBetaGroup ? "beta" : "normal";
    }

    if (authenticatedUserId && envelope.tags) {
      envelope.tags["ai.user.authUserId"] = authenticatedUserId;
    }
  };
}

export function applicationInsightsInstance() {
  if (!_instance) {
    throw new Error("ApplicationInsights not initialized");
  }
  return _instance;
}
