<script setup lang="ts">
import { ref } from "vue";

const props = withDefaults(
  defineProps<{
    actionText?: string | null;
    actionRoute?: string | null;
    icon?: string | null;
    paragraph?: string | null;
    header: string;
    loader?: boolean;
  }>(),
  {
    actionText: null,
    actionRoute: null,
    icon: null,
    loader: false,
    paragraph: null,
  },
);
const dialog = ref(true);
</script>
<template>
  <VDialog v-model="dialog" fullscreen persistent>
    <VCard color="primary" class="text-center">
      <VCardTitle primary-title class="justify-center flex-column align-center py-16 flex-nowrap">
        <VIcon v-if="!!props.icon" size="x-large" aria-hidden="true" color="white" class="mt-16 mb-8">{{ icon }}</VIcon>
        <h1 class="text-h5 font-weight-bold my-6 text-white">
          {{ header }}
        </h1>

        <VProgressCircular
          v-if="loader"
          indeterminate
          color="white"
          size="70"
          width="8"
          class="mb-16 mt-4"
        ></VProgressCircular>
      </VCardTitle>
      <VCardText class="text--primary text-center">
        <p v-if="paragraph" class="text-body-1 mb-2 px-8 text-white" style="word-break: keep-all">
          {{ paragraph }}
        </p>
      </VCardText>
      <VCardActions class="justify-center">
        <slot name="actions">
          <VBtn
            v-if="props.actionText && props.actionRoute"
            :href="props.actionRoute"
            size="x-large"
            color="white"
            class="mt-16"
            variant="elevated"
          >
            {{ actionText }}
          </VBtn>
        </slot>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
